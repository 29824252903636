<template>
  <div class="container">
    <h4 class="text-center mt-10" style="color:#4579DF">ส่งออกข้อมูลผู้กรอก</h4>
    <div class="mt-5">
      <div class="card">
        <div class="card-body px-3 py-4">
          <div class="row align-items-center">
            <div class="col-12">
              <label>เขต</label>
            </div>
            <div class="col-6">
              <b-form-select
                  v-model="formexport.district_type"
                  class="form-control"
              >
                <b-form-select-option disabled value=""
                >เลือกประเภทเขต
                </b-form-select-option
                >
                <b-form-select-option
                    v-for="(item, i) in districtType"
                    :key="i"
                    :value="item.id"
                >{{ item.name }}
                </b-form-select-option
                >
              </b-form-select>
            </div>
            <div class="col-6">
              <b-form-select class="form-control" v-model="formexport.areaCode" @change="setProvince($event)"
                             v-if="formexport.district_type === 1">
                <b-form-select-option value=""
                >เลือกเขตสุขภาพ
                </b-form-select-option
                >
                <b-form-select-option
                    v-for="(item, i) in listCode"
                    :key="i"
                    :value="item"
                >เขตสุขภาพที่{{ item }}
                </b-form-select-option
                >
              </b-form-select>
              <b-form-select
                  v-if="formexport.district_type === 2"
                  @change="setProvinceGoverment($event)"
                  v-model="formexport.goverment_code"
                  class="form-control"
              >
                <b-form-select-option value=""
                >เลือกเขตราชการ
                </b-form-select-option
                >

                <b-form-select-option
                    v-for="(item, i) in serviceCode"
                    :key="i"
                    :value="item"
                >เลือกเขตราชการที่ {{ item }}
                </b-form-select-option
                >
              </b-form-select>
            </div>

            <div class="col-6 mt-5 ">
              <label>จังหวัด</label>
              <b-form-select class="form-control" v-model="formexport.provinceCode">
                <b-form-select-option value="" disabled
                >เลือกจังหวัด
                </b-form-select-option
                >
                <b-form-select-option
                    v-for="(item, i) in province"
                    :key="i"
                    :value="item.code"
                >{{ item.name }}
                </b-form-select-option
                >
              </b-form-select>
            </div>
            <div class="col-6 ">
            </div>
            <div class="col-6 mt-5">
              <label>ประเภทของโรงพยาบาล</label>
              <b-form-select class="form-control" v-model="formexport.hospitalcode">
                <b-form-select-option value="" disabled
                >เลือกประเภทของโรงพยาบาล
                </b-form-select-option
                >
                <b-form-select-option
                    v-for="(item, i) in hospitalType"
                    :key="i"
                    :value="item.value"
                >{{ item.key }}
                </b-form-select-option
                >
              </b-form-select>
            </div>
            <div class="col-6 mt-5">
              <label>โรงพยาบาล</label>
              <b-form-select class="form-control" v-model="formexport.hospType">
                <b-form-select-option value="" disabled
                >เลือกโรงพยาบาล
                </b-form-select-option
                >
                <b-form-select-option
                    v-for="(item, i) in hospcode"
                    :key="i"
                    :value="item.hospcode"
                >{{ item.name }}
                </b-form-select-option
                >
              </b-form-select>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 mt-12" style="margin-left: 50%">
        <button class="btn btn-next-outline" @click="excel">
          ส่งออก
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import "vue2-datepicker/index.css";
import {mapState} from "vuex";

export default {
  name: "FormExportData",

  data() {
    return {
      areaCode: "",
      hospType: "",
      listCode: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      serviceCode: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18,
      ],
      provinceCode: "",
      hospitalcode: "",
      hospitalType: [
        {key: "โรงพยาบาลศูนย์_A", value: "A"},
        {key: "โรงพยาบาลทั่วไป_S", value: "S"},
        {key: "โรงพยาบาลทั่วไปขนาดเล็ก_M1", value: "M1"},
        {key: "โรงพยาบาลชุมชนแม่ข่าย_M2", value: "M2"},
        {key: "โรงพยาบาลชุมชนขนาดใหญ่_F1", value: "F1"},
        {key: "โรงพยาบาลชุมชนขนาดกลาง_F2", value: "F2"},
        {key: "โรงพยาบาลชุมชนขนาดเล็ก_F3", value: "F3"},
      ],
      districtType: [
        {id: 1, name: "เขตสุภาพ"},
        {id: 2, name: "เขตราชการ"},
      ],
      formexport: {
        areaCode: "",
        provinceCode: "",
        hospitalcode: "",
        hospType: "",
        district_type: 1,
        goverment_code: "",
        limit: 4,
      }
    }
  },

  methods: {
    setProvince(event) {
      console.log(event)
      this.$store.dispatch("MasterData/setProvinceByAreaCode", event);
      this.$store.dispatch("MasterData/setHospCode", {
        province: "",
        code: this.areaCode,
      });
    },
    hideLoading() {
      this.$loading.hide();
      this.$swal.hideLoading();
    },
    setHospital() {
      this.$store.dispatch("MasterData/setHospCode", {
        type: this.hospitalcode,
        province: this.provinceCode,
        code: this.areaCode,
      });
    },
    excel() {
      this.$store.dispatch('datauser/getformExcel', this.formexport).then(res => {
        window.open(res)
      });
    },
    setProvinceGoverment(event) {
      this.$store.dispatch("MasterData/setProvinceByGovernmentCode", event);
    },
  },

  computed: {
    ...mapState({
      paginateFormList: (state) => {
        return state.datauser.paginateFormList;
      },
      hospcode: (state) => {
        return state.MasterData.hospcode;
      },
      province: (state) => {
        return state.MasterData.province;
      },
    }),
  }
}
</script>

<style scoped>

</style>